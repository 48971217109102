import { navigate } from "gatsby";

export const selectionIdsToAffect = data => {
    return Object.values(data).filter(Boolean)
}

export const capitaliseFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const toggleAllEmployees = allEmployeesCheckbox => {
    const allSelected = allEmployeesCheckbox.target.checked

    document.querySelectorAll('.employee-approval-checkbox')
        .forEach(employeeCheckbox => employeeCheckbox.checked = allSelected)
}

let isSubmitting = false; 

export const submit = async (formData, approvalType, dispatch) => {
    if (isSubmitting) return; 
    
    try {
        isSubmitting = true;
        const selectionIds = selectionIdsToAffect(formData)
        const approval = (approvalType === 'approve')

        if (selectionIds) {
            const response = await dispatch({
                type: 'benefits/SELECTION_APPROVAL',
                payload: { selectionIds, approval }
            })

            await navigate('/app/dashboard/')
        }
    } finally {
        isSubmitting = false; 
    }
}
import { Helmet } from 'react-helmet-async'
import * as React from 'react'
import Layout from '@/container/Layout'
import Avatar from '@/components/user/Avatar'
import Text from '@/utils/Text'
import Button from '@/components/buttons/Button'
import * as m from '@/utils/messages'
import { unconfirmedSupportNumbers } from '@/utils/unconfirmedSupportNumbers'
import { useDispatch, useSelector } from 'react-redux'
import fetchApi from '@/utils/fetchApi'
import * as restAPI from '@/utils/endpoints'
import { useState } from 'react'
import { navigate } from 'gatsby'
import { AuthCodeForm } from '@/components/user/TwoFACodeSidebar/AuthCodeForm'
import Spinner from '@/utils/Spinner'
import TwoFAIcon from '@/icons/icon-two-fa.svg'
import IconWrapper from '@/utils/IconWrapper'
import Notification from '@/components/user/Notification'
import useMediaQuery from '@/hooks/useMediaQuery'

function TwoFAForm() {
	const dispatch = useDispatch();
	const {
		benefitBrandName,
		supportEmail,
		supportNumber,
		avatar
	} = useSelector(state => state.user)
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [preparedCode, setPreparedCode] = useState('');

	const isBigScreen = useMediaQuery('(min-width: 1023px)')
	const isSmallScreen = useMediaQuery('(max-width: 311px)')

	const login =
		typeof window !== 'undefined' && window.localStorage.getItem('login');
	const password =
		typeof window !== 'undefined' && window.localStorage.getItem('password');

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const response = await fetchApi(restAPI.LOGIN_WITH_2FA, {
				method: 'POST',
				body: JSON.stringify({
					code: preparedCode,
					email: login,
					password,
				}),
			});

			if (response?.error) {
				const preparedErrors = JSON.parse(response?.error)
				setErrors(preparedErrors?.[0]?.code)
				throw new Error(response.error);
			}

			await dispatch({
				type: 'user/REQUEST_LOGIN',
				payload: { email: login, password, tokenInformation: {...response} }
			})

			localStorage.removeItem('login');
			localStorage.removeItem('password');

			navigate('/app/home/')
		} catch (err) {
			console.log(err?.message)
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Layout className="flex-grow pt-lg lg:pt-2xl">
			<Helmet>
				<title>{`Login | ${benefitBrandName}`}</title>
			</Helmet>

			{errors?.length ? (
				<Notification
					level="error"
					className="text-center -mt-md mb-lg lg:-mt-lg lg:mb-xl font-h-light"
					data-testid="error"
				>
					{
						errors.map(item => (
							<Text
								key={item}
								as="p"
								className="w-full text-mob-sm"
								dangerouslySetInnerHTML={{
									__html: item
								}}
							/>
						))
					}

				</Notification>
			) : null}

			<div
				className="w-full lg:h-60vh lg:flex lg:flex-row mx-auto pb-lg max-w-login lg:max-w-none"
			>
				<div className="lg:w-1/2 lg:px-xl">
					{avatar && (
						<Avatar
							image={avatar}
							className={'w-wilb-mob md:w-wilb h-auto mb-xl'}
						/>
					)}
					<Text
						as="h1"
						className="w-full leading-tight font-h-light text-mob-2xl md:text-2xl mb-lg"
					>
						{m.WELCOME_MESSAGE(benefitBrandName)}
					</Text>
					{
						isBigScreen && (
							<div className='h-full flex items-center'>
								<Text
									as="div"
									className="w-full text-mob-sm"
									dangerouslySetInnerHTML={{
										__html: (unconfirmedSupportNumbers.includes(supportNumber) ?
											m.TROUBLE_LOGGING_IN_WITHOUT_TEL({ supportEmail }) :	m.TROUBLE_LOGGING_IN_WITH_TEL({ supportEmail, supportNumber }))
									}}
								/>
							</div>
						)
					}
				</div>

				<div className="pt-xl md:pt-2xl mb-xl lg:mb-0 lg:w-1/2 lg:px-2xl flex flex-col justify-center items-center">
					<IconWrapper icon={TwoFAIcon} wrapperClassName="w-61 h-69 text-primary mb-md md:mb-2xl" />
					<Text className="w-full mb-lg font-bold text-lg text-center">
						Enter authentication code
					</Text>
					<Text className="w-full mb-xl text-center">
						Please enter the authentication code displayed in your app
					</Text>
					{
						!isLoading ?
							<div className='flex justify-center w-full'>
								<AuthCodeForm
									setPreparedCode={setPreparedCode}
									isError={errors?.length}
									wrapperClassName='justify-center'
									onEnter={handleSubmit}
								/>
							</div>
							: (
								<div className='m-md flex justify-center'>
									<Spinner
										className="mb-xl"
										style={{ width: 80, height: 80 }}
									/>
								</div>
							)
					}
					<div className={`mb-xl ${isSmallScreen && 'flex flex-col'}`}>
						<Text
							as="button"
							type="button"
							inline
							className={`text-center text-primary md:w-auto ${isSmallScreen ? 'mb-md' : 'mr-2xl'}`}
							onClick={() => navigate('/')}
						>
							Back
						</Text>
						<Button
							data-testid="login-2fa-submit"
							as="button"
							className="px-2xl"
							onClick={handleSubmit}
						>
							Login
						</Button>
					</div>
					{
						!isBigScreen && (
							<div className='h-full flex items-center order-9'>
								<Text
									as="div"
									className="w-full text-mob-sm"
									dangerouslySetInnerHTML={{
										__html: (unconfirmedSupportNumbers.includes(supportNumber) ?
											m.TROUBLE_LOGGING_IN_WITHOUT_TEL({ supportEmail }) :	m.TROUBLE_LOGGING_IN_WITH_TEL({ supportEmail, supportNumber }))
									}}
								/>
							</div>
						)
					}
				</div>
			</div>
		</Layout>
	)
}

// export default withAuth(TwoFAForm)
export default TwoFAForm

import * as React from "react";
import { useForm } from "react-hook-form";
import Button from "@/components/buttons/Button";
import ApprovalsModal from "@/utils/ViewApprovalsData/ApprovalsModal"; 
import { toggleAllEmployees } from "@/utils/ViewApprovalsData/approvalsFormHelpers";

export const GenerateApprovalsDataTable = ({ approvalsData }) => {
    const { register, handleSubmit } = useForm();
    const [data, setData] = React.useState({});
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [type, setType] = React.useState('approve');

    const handleAction = (formData, actionType) => {
        setType(actionType);
        setData(formData);
        setShowConfirmation(true);
    };

    const approve = (formData) => handleAction(formData, 'approve');
    const reject = (formData) => handleAction(formData, 'reject');

    return (
        <>
            {showConfirmation && (
                <ApprovalsModal
                    formData={data}
                    setShowModal={setShowConfirmation}
                    approvalType={type}
                />
            )}
            <form className='w-full'>
                <div className="flex w-full justify-end">
                    <Button 
                        theme='secondary' 
                        className="mb-md mr-xl text-xl" 
                        onClick={handleSubmit(approve)}
                    >
                        Approve
                    </Button>
                    <Button 
                        className="" 
                        onClick={handleSubmit(reject)}
                    >
                        Reject
                    </Button>
                </div>

                <table className="table-auto w-full">
                    <thead className='bg-grey-20 w-full content-center text-left font-bold'>
                        <tr className='w-full'>
                            <th scope="col" className="p-sm">
                                <input
                                    className='mr-sm w-md h-md'
                                    type='checkbox'
                                    onClick={toggleAllEmployees}
                                />
                                Employees
                            </th>
                            {Object.keys(approvalsData.selectionsData[0].fields).map((key) => (
                                <th key={key} scope="col" className="p-sm">
                                    {key}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {approvalsData.selectionsData.map(({ selectionId, employeeName, orderValue, fields }) => (
                            <tr key={selectionId} className='p-sm'>
                                <td className="p-sm">
                                    <input
                                        ref={register}
                                        id={`input-${selectionId}`}
                                        type='checkbox'
                                        value={selectionId}
                                        name={selectionId}
                                        className='mr-sm w-md h-md employee-approval-checkbox'
                                    />
                                    {employeeName}
                                </td>
                                {Object.keys(fields).map((key) => (
                                    <td key={`${selectionId}-${key}`} className="p-sm">
                                        {fields[key]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </form>
        </>
    );
};